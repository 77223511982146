<!-- eslint-disable no-console -->
<script>
import Swal from "sweetalert2";
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import api from "../../../../common/axios";

/**
 * Advanced table component
 */
export default {
  page: {
    title: "Payout",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader },
  data() {
    return {
      config: {
        api: "payout",
        export: "payout/export",
      },
      reference_no: null,
      otp: '',
      tableData: [],
      title: "Payout",
      items: [
        {
          text: "Payout",
          href: "/payout",
        },
        {
          text: "View",
          href: "/apyout",
          active: true,
        },
      ],
      filtering: {
        status: "",
      },
      reference_codes: [],
      add_ctx: null,
      bulkReference: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "id",
      sortDesc: false,
      fields: [
        { key: "selected", label: "Select" },
        { key: "reference_no", label: "Nomor Referensi", sortable: true },
        { key: "job_code", label: "Kode Job", sortable: true },
        { key: "beneficiary_email", label: "Email" },
        { key: "beneficiary_name", label: "Nama", sortable: true },
        { key: "beneficiary_account", label: "No. Rekening", sortable: true },
        { key: "beneficiary_bank", label: "Bank", sortable: true },
        { key: "amount", label: "Jumlah", sortable: true },
        { key: "status", label: "Status", sortable: true },
        { key: "action" },
      ],
      routeBase: "category",
    };
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.tableData.length;
    },
  },
  methods: {
    show_modal(reference_no) {
      this.$refs["show-modal-approve"].show();
      this.reference_no = reference_no;
    },
    show_bulk() {
      this.$refs["show-modal-approve-bulk"].show();
    },
    getItems(ctx, callback) {
      const _ = this;
      this.add_ctx = ctx;
      let filter = ctx.filter ? "&key=" + ctx.filter : "";
      _.sortBy = ctx.sortBy !== null ? _.sortBy : ctx.sortBy;
      let sort = ctx.sortDesc == true ? "asc" : "desc";
      api
        .get(
          _.config.api +
            "?per_page=" +
            _.perPage +
            "&page=" +
            _.currentPage +
            "&orderby=" +
            _.sortBy +
            "&sort=" +
            sort +
            "&status=" +
            _.filtering.status +
            filter
        )
        .then((resp) => {
          const consume = resp.data.data;
          _.perPage = consume.per_page;
          _.currentPage = consume.current_page;
          _.totalRows = consume.total_data;
          callback(consume.rows || []);
        })
        .catch((err) => {
          console.log(err);
        });
      return null;
    },
    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    gotoShowPage(id) {
      let _ = this;
      _.$router.push("/" + _.routeBase + "/" + id + "/show");
    },

    goToAddPage() {
      let _ = this;
      _.$router.push("/" + _.routeBase + "/add");
    },

    reject(reference_no) {
      let _ = this;
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "Yes, reject it!",
      }).then((result) => {
        if (result.value) {
          let references = [];
          references.push(reference_no);
          let data_post = {
            reference_no: references,
          };
          api
            .put(_.config.api + "/reject", data_post)
            .then((response) => {
              this.$swal({
                icon: "success",
                title: "Berhasil Reject",
                text: response.data.data.messages,
                customClass: {
                  confirmButton: "btn btn-success",
                },
              });
              this.$refs.tableFe.refresh();
            })
            .catch((err) => {
              this.$swal({
                icon: "Danger",
                title: "Tidak bisa di reject",
                text: err.message,
                customClass: {
                  confirmButton: "btn btn-success",
                },
              });
            });
        }
      });
    },

    approve() {
      let _ = this;

      let references = [];
      references.push(_.reference_no);
      api
        .put(_.config.api + "/approve", {
          reference_no: references,
          otp: _.otp,
        })
        .then((response) => {
          this.$refs.tableFe.refresh();
          references = [];
          this.otp = null;
          this.reference_no = null;
          this.$refs["show-modal-approve"].hide();
          this.$swal({
            icon: "success",
            title: "Berhasil Diapprove",
            text: response.data.data.messages,
            customClass: {
              confirmButton: "btn btn-success",
            },
          });
        })
        .catch((e) => {
          console.log(e);
        });
    },

    approveBulk() {
      let _ = this;
      api
        .put(_.config.api + "/approve", {
          reference_no: _.reference_codes,
          otp: _.otp,
        })
        .then((response) => {
          _.reference_codes = [];
          _.$refs.tableFe.refresh();
          _.otp = null;
          _.reference_no = null;
          _.$refs["show-modal-approve-bulk"].hide();
          _.$swal({
            icon: "success",
            title: "Berhasil Diapprove",
            text: response.data.data.messages,
            customClass: {
              confirmButton: "btn btn-success",
            },
          });
        })
        .catch((e) => {
          console.log(e);
        });
    },

    changePerPage() {
      const _ = this;
      _.$refs.tableFe.refresh();
    },

    changeCurrentPage(currentPage) {
      let _ = this;
      _.currentPage = currentPage;
    },

    exportExcel() {
      let _ = this;
      let key = this.add_ctx.filter ? this.add_ctx.filter : "";
      let url = _.config.export;
      api
        .get(url, {
          params: {
            orderby: "id",
            sort: "desc",
            key: key,
            status: this.filtering.status,
          },
          responseType: "arraybuffer",
        })
        .then((response) => {
          if (response) {
            let blob = new Blob([response.data], {
              type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
            });
            let link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.download = "PayoutHistory" + new Date();
            link.click();
          }
        });
    },
  },
  mounted() {
    // Set the initial number of items
    let _ = this;
    if (_.$route.params) {
      if (_.$route.params.event) {
        _.$toast(_.$route.params.title, {
          icon: _.$route.params.icon,
          type: _.$route.params.event,
        });
      }
    }
  },
  watch: {
    "filtering.status"() {
      let _ = this;
      _.$refs.tableFe.refresh();
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row mb-md-2">
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_length" class="dataTables_length">
                  <b-button
                    @click="show_bulk"
                    variant="primary"
                    class="pl-3 pr-3"
                    v-if="reference_codes.length > 0 && can('Payout approve')"
                    >Approve Bulk</b-button
                  >
                  <b-button
                    variant="outline-primary"
                    class="ml-1"
                    @click="exportExcel"
                    v-if="can('Payout download')"
                    >Download Excel</b-button
                  >
                </div>
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-6">
                <div class="d-flex justify-content-end">
                  <div class="mr-2">
                    <select class="form-control" v-model="filtering.status">
                      <option value="">Semua Status</option>
                      <option value="approve">Approve</option>
                      <option value="reject">Reject</option>
                      <option value="pending">Pending</option>
                    </select>
                  </div>
                  <div class="mr-2">
                    <b-form-input
                      v-model="filter"
                      type="search"
                      :placeholder="$t('listTable.search')"
                      class="form-control"
                    ></b-form-input>
                  </div>
                </div>
              </div>
              <!-- End search -->
            </div>
            <!-- Table -->
            <div class="table-responsive mb-0">
              <b-table
                :items="getItems"
                :fields="fields"
                striped
                hover
                responsive="sm"
                :perPage="perPage"
                :current-page="currentPage"
                :filter="filter"
                :filter-included-fields="filterOn"
                @filtered="onFiltered"
                @changePerPage="changePerPage"
                ref="tableFe"
              >
                <template #cell(status)="data">
                  <span class="text-capitalize">{{ data.item.status }}</span>
                </template>
                <template #cell(amount)="data">
                  <span class="text-capitalize">{{
                    data.item.amount | currency
                  }}</span>
                </template>
                <template #cell(beneficiary_bank)="data">
                  <span class="text-capitalize">{{
                    data.item.beneficiary_bank
                  }}</span>
                </template>

                <template #cell(job_code)="data">
                  <span
                    v-if="data.item.job"
                    @click="$router.push(`/job/${data.item.job.id}/show`)"
                    class="text-capitalize"
                    style="cursor: pointer"
                    >{{ data.item.job.code }}</span
                  >
                  <span v-else>-</span>
                </template>

                <template v-slot:cell(selected)="data">
                  <b-form-group>
                    <b-form-checkbox
                      v-model="reference_codes"
                      :value="data.item.reference_no"
                      class="m-2"
                      size="lg"
                      v-if="data.item.status == 'pending'"
                    >
                    </b-form-checkbox>
                  </b-form-group>
                </template>

                <template #cell(action)="data">
                  <div style="min-width: 80px">
                    <b-button
                      v-if="
                        (data.item.status.toLowerCase() == 'pending')
                      "
                      v-b-tooltip.hover.top="'Approve'"
                      variant="success"
                      size="sm"
                      class="mr-1"
                      @click="show_modal(data.item.reference_no)"
                    >
                      <i class="ti-check-box"></i>
                    </b-button>

                    <b-button
                      v-if="
                        data.item.status.toLowerCase() == 'pending'
                      "
                      v-b-tooltip.hover.top="'Reject'"
                      variant="danger"
                      size="sm"
                      @click="reject(data.item.reference_no)"
                    >
                      <i class="ti-close"></i>
                    </b-button>
                  </div>
                </template>
              </b-table>
            </div>
            <div class="row">
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    {{ $t("listTable.show") }}
                    <b-form-select
                      v-model="perPage"
                      size="sm"
                      class="ml-1 mr-1"
                      :options="pageOptions"
                    ></b-form-select
                    >{{ $t("listTable.entries") }}
                  </label>
                </div>
              </div>
              <div class="col">
                <div
                  class="dataTables_paginate paging_simple_numbers float-right"
                >
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination
                      v-model="currentPage"
                      :total-rows="totalRows"
                      :per-page="perPage"
                    ></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <b-modal
        id="modal-center"
        centered
        ref="show-modal-approve"
        size="lg"
        title="Approve Payout"
        title-class="font-18"
        hide-footer
      >
        <div class="modal-body">
          <ValidationObserver v-slot="{ handleSubmit }">
            <b-form
              @submit.prevent="handleSubmit(approve)"
              class="form-horizontal"
            >
              <div class="row">
                <div class="col-md-12">
                  <!-- <ValidationProvider
                    name="Kode OTP"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <b-form-group
                      id="input-group-2"
                      label="Kode OTP"
                      label-for="input"
                    >
                      <b-form-input
                        id="input-2"
                        v-model="otp"
                        type="text"
                      ></b-form-input>
                      <p class="fs-6 text-danger">
                        {{ errors[0] }}
                      </p>
                    </b-form-group>
                  </ValidationProvider> -->
                </div>
                <div class="col-md-6">
                  <div class="form-group row">
                    <div class="col-sm-4">
                      <b-button type="submit" variant="primary" class="w-100"
                        >Approve</b-button
                      >
                    </div>
                  </div>
                </div>
              </div>
            </b-form>
          </ValidationObserver>
        </div>
      </b-modal>

      <b-modal
        id="modal-center"
        centered
        ref="show-modal-approve-bulk"
        size="lg"
        title="Approve Payout"
        title-class="font-18"
        hide-footer
      >
        <div class="modal-body">
          <ValidationObserver v-slot="{ handleSubmit }">
            <b-form
              @submit.prevent="handleSubmit(approveBulk)"
              class="form-horizontal"
            >
              <div class="row">
                <div class="col-md-12">
                  <ValidationProvider
                    name="Kode OTP"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <b-form-group
                      id="input-group-2"
                      label="Kode OTP"
                      label-for="input"
                    >
                      <b-form-input
                        id="input-2"
                        v-model="otp"
                        type="text"
                      ></b-form-input>
                      <p class="fs-6 text-danger">
                        {{ errors[0] }}
                      </p>
                    </b-form-group>
                  </ValidationProvider>
                </div>
                <div class="col-md-6">
                  <div class="form-group row">
                    <div class="col-sm-6 text-center">
                      <b-button type="submit" variant="primary" class="w-100"
                        >Yes</b-button
                      >
                    </div>
                    <div class="col-sm-6 text-center">
                      <b-button type="button" variant="primary" class="w-100" @click="$refs['show-modal-approve'].hide()"
                        >No</b-button
                      >
                    </div>
                  </div>
                </div>
              </div>
            </b-form>
          </ValidationObserver>
        </div>
      </b-modal>
    </div>
  </Layout>
</template>
